<template>
  <div>
    <table class="table table-striped table-hover">
      <thead class="thead-light">
      <tr>
        <th>#</th>
        <th>{{ $t("Service") }}</th>
        <th>{{ $t("note") }}</th>
        <th>{{ $t("price") }}</th>
        <th>{{ $t("quantity") }}</th>
        <th>{{ $t("Total") }}</th>
        <th>{{ $t("status") }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="loading">
        <td colspan="7" class="text-center">{{ $t("loading") }}...</td>
      </tr>
      <tr v-else-if="features == null || features.length === 0">
        <td colspan="7" class="text-center">{{ $t("nodatafound") }}</td>
      </tr>
      <tr v-else v-for="(request, index) in features" :key="request.featureId" @click="onSelectedFeature(request)">
        <td v-if="approveRequired(request)" class="text-warning" :title="$t('approveRequired')">
          <i class="fa-solid fa-triangle-exclamation"></i>
        </td>
        <td v-else>{{ index + 1 }}</td>
        <td><strong>{{ request.name }}</strong></td>
        <td>
                <span v-if="request.clientNote" class="text-muted">
                  {{ request.clientNote }}
                </span>
          <span v-else class="text-muted">—</span>
        </td>
        <td>{{ formatPrice(request.price) }}</td>
        <td>{{ request.amount }}</td>
        <td><strong>{{ formatPrice(request.finalPrice) }}</strong></td>
        <td>
          <span class="badge badge-pill" :class="statusClass(request.status)">
            <i :class="statusIcon(request.status)"></i>
            {{ $t(`guestFeatureStatusEnum.${request.status}`) }}
          </span>
        </td>
      </tr>
      </tbody>
    </table>
    <guest-feature-requested-modal ref="requestModal" @refresh="refresh"/>
    <guest-feature-add-requested-modal ref="addRequestModal" @refresh="refresh"/>
    <guest-feature-order-modal ref="orderModal" @refresh="refresh"/>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import GuestFeatureRequestedModal from "@/components/modals/feature/GuestFeatureRequestedModal.vue";
import GuestFeatureAddRequestedModal from "@/components/modals/feature/GuestFeatureAddRequestedModal.vue";
import GuestFeatureOrderModal from "@/components/modals/feature/GuestFeatureOrderModal.vue";

export default {
  name: "realEstateContractFeature",
  components: {GuestFeatureOrderModal, GuestFeatureAddRequestedModal, GuestFeatureRequestedModal},
  props: {
    contractId: {
      type: String,
      required: true
    }
  },
  watch: {
    contractId: {
      immediate: true,
      handler() {
        this.refresh();
      }
    }
  },
  data() {
    return {
      loading: true,
      features: []
    }
  },
  methods: {
    ...mapActions('guestFeatureRequest', ['fetchGuestFeaturesByContactId']),
    refresh(){
      let $this = this;
      this.loading = true;
      this.fetchGuestFeaturesByContactId(this.contractId).then(features => {
        $this.features = features;
      }).finally(() => {
        $this.loading = false;
      });
    },
    addNew(){
      this.$refs.addRequestModal.show(this.contractId);
    },
    makeOrder(){
      this.$refs.orderModal.show(this.contractId);
    },
    formatPrice(value) {
      return new Intl.NumberFormat("ge-AU", {style: "currency", currency: "EUR"}).format(value);
    },
    approveRequired(feature) {
      return feature.status === "REQUESTED" && feature.approved !== true;
    },
    statusClass(status) {
      return {
        "badge-success": status === "PAID",
        "badge-warning": status === "REQUESTED",
        "badge-danger": status === "CANCELED",
      };
    },
    statusIcon(status) {
      return {
        PAID: "fas fa-check-circle",
        REQUESTED: "fas fa-hourglass-half",
        CANCELED: "fas fa-times-circle",
      }[status] || "fas fa-info-circle";
    },
    onSelectedFeature(feature) {
      this.$refs.requestModal.show(feature.id);
    }
  },

}
</script>


<style scoped>

</style>