<template>
  <b-modal v-model="active" size="lg" centered hide-footer no-close-on-backdrop body-class="p-0"
           @hidden="reset">
    <template #modal-title>{{ msg("Add Guest Feature") }}</template>
    <b-card-body v-if="featureRequest != null">
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col cols="4" align-self="center">
              <b-img :src="features.selected.imageUri" fluid thumbnail style="max-width: 240px; max-height: 300px"/>
            </b-col>
            <b-col cols="8">
              <b-col cols="12">
                <b-form-group :label="msg('Name')">
                  <v-select
                      :loading="features.loading"
                      :options="features.items"
                      v-model="features.selected"
                      label="name"
                      @search="searchFeatures"
                      @input="onFeatureSelected"
                  >
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Description">
                  <b-form-textarea v-model="selectedFeature.description" readonly></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Amount">
            <b-form-input v-model="featureRequest.amount" v-bind:readonly="selectedFeature.amountAllowed !== true"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Price">
            <b-form-input v-model="featureRequest.price"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Final Price">
            <b-form-input v-model="finalPrice" readonly></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12">
        <b-form-group label="Note">
          <b-form-textarea v-model="featureRequest.note"  ></b-form-textarea>
        </b-form-group>
        </b-col>
      </b-row>
      <b-button block variant="success" @click="addToContract" v-bind:disabled="canAdd !== true">{{ msg('Add') }}</b-button>
    </b-card-body>

  </b-modal>
</template>

<script>
import {mapActions} from "vuex";

const createGuestFeatureRequest = () => {
  return {featureId: null, amount: null, note: "", agentNote: null, price: null}
}

export default {
  name: "GuestFeatureAddRequestedModal",
  data: () => ({
    active: false,
    featureRequest: null,
    contractId: null,
    features: {
      loading: false,
      items: [],
      selected: {},
    },
  }),
  computed:{
    selectedFeature() {
      return this.features?.selected || {};
    },
    finalPrice() {
      let finalPrice = this.featureRequest.amount * this.featureRequest.price;
      return isNaN(finalPrice) ? null : finalPrice;
    },
    canAdd(){
      return this.featureRequest?.featureId != null && this.finalPrice > 0;
    }
  },
  methods: {
    ...mapActions('guestFeatureRequest', ['fetchAllGuestFeatureByContactId', 'getGuestFeaturesByContactId', 'addGuestFeaturesToContactId']),
    show(contractId) {
      this.reset();
      this.contractId = contractId;
      this.searchFeatures("",()=>{}); // reset search
      this.active = true;
    },
    searchFeatures(text, loading) {
      let $this = this;
      let filter = {name: text};
      this.features.loading = true;
      loading(true);
      this.fetchAllGuestFeatureByContactId(this.contractId).then(features => {
        $this.features.items = features;
      }).finally(() => {
        $this.features.loading = false;
        loading(false);
      })
    },
    onFeatureSelected() {
      if(this.features.selected == null || this.features.selected.id == null){
        this.featureRequest.featureId = null;
        return;
      }
      this.featureRequest.featureId = this.features.selected.id;
      if(this.features.amountAllowed !== true){
        this.featureRequest.amount = 1;
      }
      this.featureRequest.price = this.features.selected.rate;
    },
    addToContract(){
      let $this = this;
      this.addGuestFeaturesToContactId({contractId: this.contractId, request: this.featureRequest}).then(() => {
        $this.active = false;
        $this.$emit('added');
      })
    },
    reset() {
      this.features.selected = {};
      this.features.items = [];
      this.featureRequest = createGuestFeatureRequest();
      this.active = false;
      this.feature = null;
      this.featureId = null;
    }
  }
}
</script>

<style scoped>

</style>