<template>
  <b-modal v-model="active" size="lg" centered hide-footer no-close-on-backdrop body-class="p-0"
           @hidden="reset">
    <template #modal-title>
      <h4 class="m-0">Features Requested</h4>
    </template>
    <div  class="px-2 text-center text-muted">Click in title to expand </div>
    <div class="p-1" v-if="order != null">
      <b-row>
        <b-col md="12">
          <b-card v-for="feature in order.featuresRequested" :key="feature.id" class="mb-1" :border-variant="feature.clientRequest ? 'success' : 'info'">
            <div class="text-right" style="float: right; margin-right: -15px; margin-top: -15px ">
              <b-badge size="sm" v-if="feature.clientRequest" variant="success" style="margin: 0 5px">
                Client Request
              </b-badge>
            </div>
            <b-card-title v-b-toggle="`accordion-${feature.id}`" class="mb-1">{{ feature.name }}</b-card-title>
            <b-collapse :id="`accordion-${feature.id}`"  accordion="my-accordion" role="tabpanel">
              <div><p>{{ feature.description }}</p></div>
              <div>
                <div id="client-note-collapse">
                  <strong>Client Note:</strong>
                  <p v-if="feature.clientNote">{{ feature.clientNote }}</p>
                  <p v-else class="text-muted">No client note provided</p>
                </div>

              </div>
            </b-collapse>
            <div id="agent-note-collapse">
              <b-form-group label="Agent Note:" class="mb-0">
                <b-form-textarea
                    rows="3"
                    v-model="feature.agentNote"></b-form-textarea>
              </b-form-group>
            </div>
            <hr>
            <b-row>
              <b-col md="4">
                <strong>Price:</strong> €{{ feature.price }}
              </b-col>
              <b-col md="4">
                <strong>Amount: </strong>
                <span>{{ feature.amount }}</span>
                <span v-if="feature.dailyPayment"> * {{feature.days}} {{$t('days')}}</span>
              </b-col>
              <!--                <b-col md="3">
                                <strong>Days:</strong> {{ feature.days }}
                              </b-col>-->
              <b-col md="4">
                <strong>Final Price:</strong> € {{ feature.finalPrice }}
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-button block variant="primary" @click="payOrder" v-bind:disabled="busy">
        {{$t('Pay')}}  €{{ totalPrice }}
      </b-button>
    </div>
  </b-modal>
</template>
<script>
import {mapActions} from "vuex";

export default {
  name: "GuestFeatureOrderModal",
  data: () => ({
    active: false,
    loading: false,
    busy: false,
    contractId: null,
    order: null,
  }),
  computed:{
    totalPrice(){
      return this.order.featuresRequested.reduce((total, feature) => {
        return total + feature.finalPrice
      }, 0);
    }
  },
  methods:{
    ...mapActions('guestFeatureRequest', ['generateOrderForContract', 'payOrderForContract']),
    show(contractId){
      this.contractId = contractId;
      this.generateOrder()
      this.active = true;
    },
    generateOrder(){
      let $this = this;
      this.loading = true;
      $this.generateOrderForContract( this.contractId).then(order => {
        $this.order = order;
      }).catch(()=>{
        $this.active = false;
      }).finally(() => {
        $this.loading = false;
      })
    },
    reset(){
      this.active = false;
      this.order = null;
    },
    payOrder() {
      let $this = this;
      this.busy = true;
      let request = this.order.featuresRequested.map(f => ({featureId: f.id, agentNote: f.agentNote}));
      this.payOrderForContract({orderId: this.order.orderId, request: {request}}).then(() => {
        $this.$emit('refresh');
        $this.reset();
      })
    }
  }
}
</script>



<style scoped>

</style>