<template>
  <b-modal v-model="active" size="lg" centered hide-footer no-close-on-backdrop body-class="p-0"
           @hidden="reset">
    <template #modal-title>{{ msg("Guest Feature") }}</template>
    <b-card-body v-if="feature != null">
      <b-row>
        <b-col cols="6">
          <b-form-group label="Feature Name">
            <b-form-input v-model="feature.name" readonly></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Status">
            <b-form-input v-model="feature.status" readonly></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Description">
            <b-form-textarea v-model="feature.description" readonly></b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col :cols="feature.dailyPayment ? 3 : 4">
          <b-form-group label="Amount">
            <b-form-input v-model="feature.amount"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col v-if="feature.dailyPayment" >
          <b-form-group label="days">
            <b-form-input v-model="feature.days"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col :cols="feature.dailyPayment ? 3 : 4">
          <b-form-group label="Price">
            <b-form-input v-model="feature.price" readonly></b-form-input>
          </b-form-group>
        </b-col>
        <b-col :cols="feature.dailyPayment ? 3 : 4">
          <b-form-group label="Final Price">
            <b-form-input v-bind:value="finalPrice" readonly></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Client Note">
            <b-form-textarea v-model="feature.clientNote"  readonly></b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Agent Note">
            <b-form-textarea v-model="feature.agentNote" ></b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col cols="12" >
          <b-form-group>
            <b-form-checkbox v-bind:disabled="canRemove !== true" v-model="feature.approved">
              {{ msg('Approved') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!canUpdateOnly">
        <b-col cols="6">
          <b-button block variant="outline-warning" @click="remove" v-if="canRemove">{{ msg('Remove') }}</b-button>
        </b-col>
        <b-col cols="6">
          <b-button block variant="outline-success" @click="update" v-if="canRemove">{{ msg('Update') }}</b-button>
        </b-col>
      </b-row>
      <div v-else>
        <b-button block variant="outline-success" @click="update" >{{ msg('Update') }}</b-button>
      </div>
    </b-card-body>

  </b-modal>
</template>

<script>
import {mapActions} from "vuex";

const createGuestFeatureRequest = () => {
  return {featureId: null, days: null, amount: null, note: "", agentNote: null, price: null, approved: false}
}

export default {
  name: "GuestFeatureRequestedModal",
  data: () => ({
    active: false,
    featureId: null,
    feature: null,
  }),
  computed:{
    canUpdateOnly(){
      return this.feature?.status === 'PAID';
    },
    canRemove(){
      return this.feature?.status === 'REQUESTED';
    },
    finalPrice() {
      if (this.feature == null) return null;
      if(this.feature.dailyPayment === true){
        let finalPrice = this.feature.amount * this.feature.price * this.feature.days;
        return isNaN(finalPrice) ? null : finalPrice;
      }else {
        let finalPrice = this.feature.amount * this.feature.price;
        return isNaN(finalPrice) ? null : finalPrice;
      }
    },
  },
  methods: {
    ...mapActions('guestFeatureRequest', ['getGuestFeaturesByContactId', 'updateGuestFeaturesToContactId', 'cancelGuestFeaturesByContactId']),
    show(featureId) {
      this.active = true;
      this.featureId = featureId;
      this.refresh();
    },
    refresh() {
      let $this = this;
      this.getGuestFeaturesByContactId(this.featureId).then(feature => {
        $this.feature = feature;
      })
    },
    remove(){
      let $this = this;
      this.cancelGuestFeaturesByContactId(this.featureId).then(() => {
        $this.$emit('refresh');
        $this.reset();
      })
    },
    update(){
      let $this = this;
      let request = {...createGuestFeatureRequest(),
        agentNote: this.feature.agentNote,
        approved: this.feature.approved,
        amount: this.feature.amount,
      };
      if(this.feature.dailyPayment === true) {
        request.days = this.feature.days;
      }
      this.updateGuestFeaturesToContactId({featureId: this.featureId, request}).then(() => {
        $this.$emit('refresh');
        $this.reset();
      })
    },
    reset() {
      this.active = false;
      this.feature = null;
      this.featureId = null;
    }
  }
}
</script>

<style scoped>

</style>